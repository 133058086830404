import React, { useState, useEffect, useCallback } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import 'react-diff-view/style/index.css';
import { Diff, Hunk, parseDiff } from 'react-diff-view';
import ReactMarkdown from 'react-markdown';

const MerlinAnalysis = () => {
  const [ap, setAp] = useState(null);
  const [issueId, setIssueId] = useState('');
  const [selectedProject, setSelectedProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [analysis, setAnalysis] = useState(null);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [isPostingQuestions, setIsPostingQuestions] = useState(false);
  const [questionsPosted, setQuestionsPosted] = useState(false);
  const [analysisExists, setAnalysisExists] = useState(null);
  const [analysisDate, setAnalysisDate] = useState(null);
  const [isAnalysisOutdated, setIsAnalysisOutdated] = useState(false);
  const [isGeneratingAnalysis, setIsGeneratingAnalysis] = useState(false);
  const [issueUpdateTime, setIssueUpdateTime] = useState(null);
  const [loadingDots, setLoadingDots] = useState('');
  const [analysisLogId, setAnalysisLogId] = useState(null);
  const [feedbackHistory, setFeedbackHistory] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [analysisProgress, setAnalysisProgress] = useState({});

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      @keyframes spin {
        from { transform: rotate(0deg); }
        to { transform: rotate(360deg); }
      }
    `;
    document.head.appendChild(style);

    // Cleanup
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  useEffect(() => {
    const initializeAP = async () => {
      if (window.AP) {
        try {
          await new Promise((resolve) => window.AP.context.getContext(resolve));
          setAp(window.AP);
          setIsLoading(false);
        } catch (error) {
          console.error('Error initializing AP:', error);
          setError('Error initializing Atlassian Connect. Please refresh the page.');
          setIsLoading(false);
        }
      } else {
        console.error('AP is not available');
        setError('Atlassian Connect is not available. Please check your installation.');
        setIsLoading(false);
      }
    };

    initializeAP();
  }, []);

  useEffect(() => {
    if (ap) {
      fetchIssueContext();
      checkForUpdates();
    }
  }, [ap]);

  useEffect(() => {
    if (!ap) return;

    // Create resize observer
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        const height = entry.contentRect.height;
        console.log('Content height changed:', height);
        ap.resize('100%', height + 60);
      }
    });

    // Observe the container
    const container = document.getElementById('merlin-analysis-container');
    if (container) {
      resizeObserver.observe(container);
    }

    // Cleanup
    return () => {
      resizeObserver.disconnect();
    };
  }, [ap]);

  const fetchIssueContext = useCallback(async () => {
    if (!ap || !ap.context) {
      setError('AP context is not available. Please try again later.');
      return;
    }

    try {
      // Get issue context
      const context = await ap.context.getContext();
      const issueKey = context.jira.issue.key;
      const projectId = context.jira.project.id;

      // Get full issue details including update time
      const issueResponse = await ap.request(`/rest/api/3/issue/${issueKey}`);
      const issueData = JSON.parse(issueResponse.body);
      const newUpdateTime = new Date(issueData.fields.updated);
      console.log('Issue Update Time:', newUpdateTime.toISOString());
      
      // Update state with issue info
      setIssueId(issueKey);
      setSelectedProject({ id: projectId, name: projectId });
      setIssueUpdateTime(newUpdateTime);

      // Fetch analysis
      const token = await ap.context.getToken();
      const analysisResponse = await fetch(`/get-analysis?projectId=${projectId}&issueId=${issueKey}`, {
        method: 'GET',
        headers: {
          'Authorization': `JWT ${token}`,
          'Accept': 'application/json'
        },
        credentials: 'include'
      });

      if (!analysisResponse.ok) {
        throw new Error(`HTTP error! status: ${analysisResponse.status}`);
      }

      const result = await analysisResponse.json();
      console.log('Analysis Response:', result);
      
      // Update analysis state
      setAnalysisExists(!!result.analysis);
      setAnalysisDate(result.generatedAt);
      setAnalysis(result.analysis);
      if (result.analysisLogId) {
        console.log('Setting analysisLogId from context:', result.analysisLogId);
        setAnalysisLogId(result.analysisLogId);
      }

      // Compare dates for outdated check
      if (newUpdateTime && result.generatedAt) {
        const analysisDate = new Date(result.generatedAt);
        console.log('Date comparison:', {
          analysisDate: analysisDate.toISOString(),
          issueUpdateTime: newUpdateTime.toISOString(),
          isOutdated: analysisDate < newUpdateTime
        });
        
        setIsAnalysisOutdated(analysisDate < newUpdateTime);
      }

    } catch (error) {
      console.error('Error fetching issue context or analysis:', error);
      setError('Failed to load issue data');
    }
  }, [ap]);

  const handleFeedbackSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitting feedback with analysisLogId:', analysisLogId);
    if (!analysisLogId) {
      console.error('No analysis log ID available');
      return;
    }

    try {
      const token = await ap.context.getToken();
      const response = await fetch('/submit-feedback', {
        method: 'POST',
        headers: {
          'Authorization': `JWT ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          projectId: selectedProject.id,
          issueId: issueId,
          analysisLogId,
          rating,
          comment
        })
      });

      if (!response.ok) {
        throw new Error('Failed to submit feedback');
      }

      // Track feedback submission
      trackEvent('submit_feedback', {
        rating: rating,
        has_comment: !!comment,
        analysis_id: analysisLogId
      });

      // Clear form
      setRating(0);
      setComment('');
      
      // Fetch updated feedback list
      await fetchFeedback();
      
    } catch (error) {
      console.error('Error submitting feedback:', error);
      AP.flag.create({
        type: 'error',
        title: 'Error',
        body: 'Failed to submit feedback. Please try again.',
        close: 'auto'
      });
    }
  };

  const handleAcceptSolution = async () => {
    setIsAccepting(true);
    try {
      const result = await invoke('accept-solution', { projectId: selectedProject.id, issueId: issueId });
      alert(result.message);
    } catch (error) {
      console.error('Error accepting solution:', error);
      alert('Failed to accept solution. Please try again later.');
    } finally {
      setIsAccepting(false);
    }
  };

  const openDetailedView = () => {
    ap.dialog.create({
      key: 'detailedMerlinAnalysis',
      chrome: false,
      width: '80%',
      height: '80%',
      url: `/issue-panel?fullView=true&issueId=${issueId}&projectId=${selectedProject.id}`,
    });
  };

  const handleTabSelect = (index) => {
    console.log('Tab selected:', index);
    setActiveTab(index);
    
    // Always check for updates when changing tabs
    checkForUpdates();
    
    trackEvent('tab_view', { 
      tab_name: ['Overview', 'Solution', 'Workaround', 'Recommendations', 'Open Questions', 'Feedback'][index]
    });
    
    if (ap) {
      document.dispatchEvent(new Event('react-tabs-selected'));
    }
    if (index === 5) {
      console.log('Feedback tab selected, fetching feedback...');
      fetchFeedback();
    }
  };

  const handlePostQuestions = async () => {
    trackEvent('post_questions');
    if (!analysis?.additionalQuestions || !ap || questionsPosted) {
      return;
    }

    setIsPostingQuestions(true);
    try {
      const token = await ap.context.getToken();
      const context = await ap.context.getContext();
      const issueKey = context.jira.issue.key;

      const questionsText = `*Clarifying Questions from CodeMerlin:*\n${analysis.additionalQuestions
        .map(q => `• ${q}`)
        .join('\n')}`;

      const response = await fetch(`/post-comment?issueId=${issueKey}`, {
        method: 'POST',
        headers: {
          'Authorization': `JWT ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ comment: questionsText })
      });

      if (!response.ok) {
        throw new Error('Failed to post comment');
      }

      ap.flag.success({ body: 'Questions posted as a comment!' });
      setQuestionsPosted(true);
      
      ap.refreshIssuePage();
    } catch (error) {
      console.error('Error posting questions:', error);
      ap.flag.error({ body: 'Failed to post questions. Please try again.' });
    } finally {
      setIsPostingQuestions(false);
    }
  };

  const handleGenerateAnalysis = async () => {
    setIsGeneratingAnalysis(true);
    setError(null);
    
    try {
      const token = await ap.context.getToken();
      const response = await fetch('/generate-analysis', {
        method: 'POST',
        headers: {
          'Authorization': `JWT ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          projectId: selectedProject.id,
          issueId: issueId
        })
      });

      if (!response.ok) {
        throw new Error('Failed to generate analysis');
      }

      const result = await response.json();
      
      // Handle different states
      switch (result.status) {
        case 'waiting_for_indexing':
          setError(`Can not generate analysis while repositories are being indexed: ${result.unindexedRepos}`);
          setIsGeneratingAnalysis(false);
          return;
          
        case 'analysis_in_progress':
          // Start polling with normal flow
          startPolling();
          break;
        
        case 'no_repositories_found':
          setError(result.message || 'No code repositories found for analysis. Please map your repositories for this project in the CodeMerlin configuration to generate an analysis.');
          setIsGeneratingAnalysis(false);
          return;
          
        case 'error':
          setError(result.message || 'Failed to generate analysis');
          setIsGeneratingAnalysis(false);
          return;
          
        default:
          // Normal flow for 'started' status
          startPolling();
      }

    } catch (error) {
      console.error('Error generating analysis:', error);
      setError('Failed to generate analysis. Please try again.');
      setIsGeneratingAnalysis(false);
    }
  };

  // Separate polling logic for cleaner code
  const startPolling = () => {
    setLoadingDots(''); // Reset dots at start
    const pollInterval = setInterval(async () => {
      try {
        const result = await fetchAnalysis(selectedProject.id, issueId);
        if (result?.analysis) {
          clearInterval(pollInterval);
          setIsGeneratingAnalysis(false);
          setAnalysisExists(true);
          setAnalysis(result.analysis);
          setAnalysisDate(result.generatedAt);
          setLoadingDots(''); // Reset dots when done
        } else {
          // Just add another dot, no reset
          setLoadingDots(dots => dots + '.');
        }
        checkForUpdates();
      } catch (error) {
        console.error('Polling error:', error);
      }
    }, 20000);

    // Clear interval after 5 minutes
    setTimeout(() => {
      clearInterval(pollInterval);
      if (isGeneratingAnalysis) {
        setIsGeneratingAnalysis(false);
        setError('Analysis generation timed out. Please try again.');
        setLoadingDots(''); // Reset dots on timeout
      }
    }, 500000);
  };

  const handleRegenerateAnalysis = async () => {
    setIsGeneratingAnalysis(true);
    setLoadingDots(''); // Reset dots at start
    const regenerateStartTime = new Date();
    
    try {
      const token = await ap.context.getToken();
      const response = await fetch('/generate-analysis', {
        method: 'POST',
        headers: {
          'Authorization': `JWT ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          projectId: selectedProject.id,
          issueId: issueId
        })
      });

      if (!response.ok) {
        throw new Error('Failed to generate analysis');
      }

      // Poll for new analysis
      const pollInterval = setInterval(async () => {
        try {
          const result = await fetchAnalysis(selectedProject.id, issueId);
          // Only update if we get an analysis generated after we started regenerating
          if (result?.analysis && new Date(result.generatedAt) > regenerateStartTime) {
            clearInterval(pollInterval);
            setIsGeneratingAnalysis(false);
            setAnalysisExists(true);
            setAnalysis(result.analysis);
            setAnalysisDate(result.generatedAt);
            setLoadingDots(''); // Reset dots when done
          } else {
            // Just add another dot, no reset
            setLoadingDots(dots => dots + '.');
          }
        } catch (error) {
          console.error('Polling error:', error);
        }
      }, 20000);

      // Clear interval after 5 minutes
      setTimeout(() => {
        clearInterval(pollInterval);
        if (isGeneratingAnalysis) {
          setIsGeneratingAnalysis(false);
          setError('Analysis generation is taking longer than expected. Please check back in a few minutes.');
          console.log('Analysis generation is taking longer than expected.');
          setLoadingDots(''); // Reset dots on timeout
        }
      }, 300000);

    } catch (error) {
      console.error('Error generating analysis:', error);
      setError('Failed to generate analysis');
      setIsGeneratingAnalysis(false);
      setLoadingDots(''); // Reset dots on error
    }
  };

  const styles = {
    container: {
      padding: '20px',
      backgroundColor: 'white',
      borderRadius: '3px',
      boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
      fontFamily: "'Roboto', sans-serif",
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
      padding: '8px 0'
    },
    leftHeader: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px'  // Space between logo and title
    },
    logo: {
      height: '24px',
      width: 'auto'
    },
    heading: {
      color: '#0052CC',
      fontSize: '20px',
      margin: 0,
      fontFamily: "'Roboto', sans-serif",
      fontWeight: 500,
    },
    tabContent: {
      padding: '20px',
      backgroundColor: 'white',
      border: '1px solid #ddd',
      borderTop: 'none',
    },
    sectionTitle: {
      color: '#172B4D',
      fontSize: '18px',
      marginTop: '20px',
      marginBottom: '10px',
    },
    paragraph: {
      lineHeight: '1.5',
      marginBottom: '15px',
    },
    list: {
      paddingLeft: '20px',
      marginBottom: '15px',
    },
    listItem: {
      marginBottom: '10px',
      lineHeight: '1.5',
    },
    codeBlock: {
      marginBottom: '20px',
      border: '1px solid #ddd',
      borderRadius: '3px',
    },
    codeTitle: {
      backgroundColor: '#f4f5f7',
      padding: '8px 12px',
      borderBottom: '1px solid #ddd',
      fontWeight: 'bold',
    },
    code: {
      backgroundColor: '#f8f9fa',
      padding: '12px',
      overflowX: 'auto',
      fontFamily: "'Roboto Mono', monospace",
      fontSize: '14px',
      lineHeight: '1.4',
    },
    button: {
      padding: '8px 16px',
      backgroundColor: '#0052CC',
      color: 'white',
      border: 'none',
      borderRadius: '3px',
      cursor: 'pointer',
      fontSize: '14px',
      marginTop: '10px',
    },
    diffContainer: {
      margin: '10px 0',
      border: '1px solid #ddd',
      borderRadius: '3px',
    },
    diffWrapper: {
      fontSize: '14px',
      fontFamily: "'Roboto Mono', monospace",
      backgroundColor: '#fff',
      border: '1px solid #ddd',
      borderRadius: '3px',
      overflow: 'auto',
    },
    rightHeader: {
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'center',
      gap: '10px'
    },
    updateButton: {
      backgroundColor: '#0052CC',
      color: 'white',
      border: 'none',
      borderRadius: '3px',
      padding: '6px 12px',
      cursor: 'pointer',
      fontSize: '14px'
    },
    analysisInProgressBanner: {
      backgroundColor: '#DEEBFF',
      color: '#0747A6',
      padding: '8px 16px',
      borderRadius: '3px',
      marginBottom: '16px'
    },
    spinner: {
      display: 'inline-block',
      marginLeft: '8px',
      animation: 'spin 2s linear infinite',
    },
    feedbackText: {
      lineHeight: '1.5',
      color: '#172B4D',
      marginBottom: '20px',
      fontSize: '14px',
      backgroundColor: '#F4F5F7',
      padding: '12px',
      borderRadius: '3px',
      border: '1px solid #DFE1E6'
    },
    feedbackHistory: {
      marginTop: '20px',
      padding: '20px',
      backgroundColor: '#fff',
      borderRadius: '3px',
      boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    },
    subSectionTitle: {
      color: '#172B4D',
      fontSize: '18px',
      marginBottom: '10px',
    },
    feedbackEntry: {
      backgroundColor: '#F4F5F7',
      padding: '20px',
      borderRadius: '3px',
      marginBottom: '15px',
      border: '1px solid #DFE1E6'
    },
    feedbackHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '5px',
    },
    stars: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
    },
    feedbackDate: {
      color: '#666',
      fontSize: '14px',
    },
    feedbackComment: {
      margin: '10px 0 0 0',
      color: '#172B4D',
      fontSize: '14px',
      lineHeight: '1.5',
      '& p': {
        margin: '0 0 10px 0'
      },
      '& ul, & ol': {
        marginLeft: '20px'
      },
      '& code': {
        backgroundColor: '#F4F5F7',
        padding: '2px 4px',
        borderRadius: '3px',
        fontSize: '12px'
      }
    },
    disclaimer: {
      fontSize: '12px',
      color: '#6B778C',
      padding: '8px 16px',
      borderBottom: '1px solid #DFE1E6',
      backgroundColor: '#F4F5F7',
      marginBottom: '16px'
    },
    generateButton: {
      backgroundColor: '#0052CC',
      color: 'white',
      padding: '8px 16px',
      borderRadius: '3px',
      border: 'none',
      fontSize: '14px',
      fontWeight: 500,
      cursor: 'pointer',
      transition: 'background-color 0.2s ease',
      display: 'inline-flex',
      alignItems: 'center',
      gap: '8px',
      boxShadow: '0 1px 1px rgba(9, 30, 66, 0.25)',
      ':hover': {
        backgroundColor: '#0065FF'
      },
      ':active': {
        backgroundColor: '#0747A6',
        boxShadow: 'none'
      },
      ':disabled': {
        backgroundColor: '#091E420',
        cursor: 'not-allowed'
      }
    },
    progressContainer: {
      width: '200px',
      height: '4px',
      backgroundColor: '#DFE1E6',
      borderRadius: '2px',
      marginLeft: '10px',
      display: 'inline-block'
    },
    progressBar: {
      height: '100%',
      backgroundColor: '#0052CC',
      borderRadius: '2px',
      transition: 'width 0.3s ease-in-out'
    }
  };

  const CodeDiff = ({ oldCode, newCode }) => {
    try {
      // Split both code blocks into lines and clean them
      const oldLines = oldCode.split('\n');
      const newLines = newCode.split('\n');
      
      // Find the actual differences
      let diffLines = [];
      let maxLength = Math.max(oldLines.length, newLines.length);
      
      for (let i = 0; i < maxLength; i++) {
        const oldLine = oldLines[i] || '';
        const newLine = newLines[i] || '';
        
        if (oldLine !== newLine) {
          if (oldLine) diffLines.push(`-${oldLine}`);
          if (newLine) diffLines.push(`+${newLine}`);
        } else {
          diffLines.push(` ${oldLine}`); // Unchanged lines
        }
      }

      // Create the diff text with only the changes
      const diffText = `diff --git a/file.js b/file.js
--- a/file.js
+++ b/file.js
@@ -1,${oldLines.length} +1,${newLines.length} @@
${diffLines.join('\n')}`;

      const files = parseDiff(diffText);

      return (
        <div style={{ 
          fontSize: '14px', 
          fontFamily: 'monospace',
          border: '1px solid #ddd',
          borderRadius: '4px',
          overflow: 'auto'
        }}>
          {files.map((file, i) => (
            <Diff 
              key={i}
              viewType="unified"
              diffType={file.type}
              hunks={file.hunks}
              gutterType="anchor"
              className="diff-view"
            >
              {hunks => hunks.map(hunk => (
                <Hunk 
                  key={hunk.content}
                  hunk={hunk}
                  gutterEvents={true}
                />
              ))}
            </Diff>
          ))}
        </div>
      );
    } catch (error) {
      console.error('Error in CodeDiff:', error);
      return <div>Error displaying diff</div>;
    }
  };

  const trackEvent = async (eventName, params = {}) => {
    try {
      const token = await ap.context.getToken();
      await fetch('/analytics', {
        method: 'POST',
        headers: {
          'Authorization': `JWT ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          event: eventName,
          params: params
        })
      });
    } catch (error) {
      console.error('Error tracking event:', error);
    }
  };

  const renderHeader = () => (
    <div style={styles.headerContainer}>
      <div style={styles.leftHeader}>
        <img src="/images/codemerlinlogo.png" alt="CodeMerlin Logo" style={styles.logo} />
        <h1 style={styles.heading}>CodeMerlin Analysis</h1>
      </div>
      {analysisExists && (
        <div style={styles.rightHeader}>
          <span>Analysis Generated: {new Date(analysisDate).toLocaleString()}</span>
          {isAnalysisOutdated && (
            <button 
              onClick={handleRegenerateAnalysis}
              style={styles.updateButton}
              disabled={isGeneratingAnalysis}
            >
              {isGeneratingAnalysis ? 'Updating...' : 'Update Analysis'}
            </button>
          )}
        </div>
      )}
    </div>
  );

  const renderContent = () => {
    if (error) {
      return <div style={styles.error}>{error}</div>;
    }

    if (isLoading) {
      return (
        <div style={styles.centerContent}>
          <p>Loading...</p>
        </div>
      );
    }

    if (analysisExists === false && !isGeneratingAnalysis) {
      return (
        <div style={styles.centerContent}>
          <button 
            onClick={handleGenerateAnalysis}
            style={styles.generateButton}
            disabled={isGeneratingAnalysis}
          >
            {isGeneratingAnalysis ? (
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <span>Analyzing</span>
                <div style={styles.progressContainer}>
                  <div 
                    style={{
                      ...styles.progressBar,
                      width: `${analysisProgress['analysis'] || 0}%`
                    }}
                  />
                </div>
              </div>
            ) : (
              'Run Analysis'
            )}
          </button>
        </div>
      );
    }

    if (isGeneratingAnalysis && !analysisExists) {
      return (
        <div style={styles.centerContent}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            <p>Analysis in progress{loadingDots}</p>
            <div style={styles.progressContainer}>
              <div 
                style={{
                  ...styles.progressBar,
                  width: `${analysisProgress['analysis'] || 0}%`
                }}
              />
            </div>
          </div>
          <p>This may take a few minutes to complete.</p>
        </div>
      );
    }

    if (analysisExists === null || analysisExists === undefined) {
      return (
        <div style={styles.centerContent}>
          <p>Loading analysis...</p>
        </div>
      );
    }

    return (
      <>
        {isGeneratingAnalysis && (
          <div style={styles.analysisInProgressBanner}>
            Generating updated analysis...
          </div>
        )}
        <Tabs selectedIndex={activeTab} onSelect={handleTabSelect}>
          <TabList>
            <Tab>Overview</Tab>
            <Tab>Solution</Tab>
            <Tab>Workaround</Tab>
            <Tab>Recommendations</Tab>
            <Tab>Open Questions</Tab>
            <Tab>Feedback</Tab>
          </TabList>

          <TabPanel style={styles.tabContent}>
            <h2 style={styles.sectionTitle}>Original Issue Information</h2>
            <p><strong>Repository:</strong> <a href={analysis.repository} target="_blank" rel="noopener noreferrer">{analysis.repositories}</a></p>
            <p><strong>Issue:</strong> <a href={analysis.issueLink} target="_blank" rel="noopener noreferrer">{analysis.issueTitle} (#{analysis.issueNumber})</a></p>
            <h2 style={styles.sectionTitle}>Root Cause</h2>
            <p style={styles.paragraph}>{analysis.rootCause}</p>
          </TabPanel>

          <TabPanel style={styles.tabContent}>
            <h2 style={styles.sectionTitle}>Solution</h2>
            <p style={styles.paragraph}>{analysis.solution}</p>
            
            <h2 style={styles.sectionTitle}>Code Changes</h2>
            {analysis.codeChanges?.map((change, index) => (
              <div key={index} style={styles.codeBlock}>
                <h3 style={styles.codeTitle}>{change.file} ({change.repository})</h3>
                <p><strong>Description:</strong> {change.description}</p>
                <div style={styles.diffContainer}>
                  <CodeDiff
                    oldCode={change.currentCode || ''}
                    newCode={change.suggestedCode || ''}
                  />
                </div>
              </div>
            ))}
            <h3 style={styles.sectionTitle}>Explanation of Changes</h3>
            <ul style={styles.list}>
              {analysis.explanation?.map((item, index) => (
                <li key={index} style={styles.listItem}>{item}</li>
              ))}
            </ul>
          </TabPanel>

          <TabPanel style={styles.tabContent}>
            {analysis.potentialWorkaround ? (
              <>
                <h2 style={styles.sectionTitle}>Potential Workaround</h2>
                <p style={styles.paragraph}><strong>Description:</strong> {analysis.potentialWorkaround.description}</p>
                <h3 style={styles.sectionTitle}>Steps:</h3>
                <ol style={styles.list}>
                  {analysis.potentialWorkaround.steps.map((step, index) => (
                    <li key={index} style={styles.listItem}>{step}</li>
                  ))}
                </ol>
                <h3 style={styles.sectionTitle}>Limitations:</h3>
                <p style={styles.paragraph}>{analysis.potentialWorkaround.limitations}</p>
              </>
            ) : (
              <p>No workaround available for this issue.</p>
            )}
          </TabPanel>

          <TabPanel style={styles.tabContent}>
            <h2 style={styles.sectionTitle}>Additional Recommendations</h2>
            <ul style={styles.list}>
              {analysis.recommendations?.map((item, index) => (
                <li key={index} style={styles.listItem}>{item}</li>
              ))}
            </ul>
            <h2 style={styles.sectionTitle}>Next Steps</h2>
            <ol style={styles.list}>
              {analysis.nextSteps?.map((item, index) => (
                <li key={index} style={styles.listItem}>{item}</li>
              ))}
            </ol>
          </TabPanel>

          <TabPanel style={styles.tabContent}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h2 style={styles.sectionTitle}>Open Questions</h2>
              {!questionsPosted ? (
                <button
                  onClick={handlePostQuestions}
                  disabled={isPostingQuestions}
                  style={{
                    ...styles.button,
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px'
                  }}
                >
                  {isPostingQuestions ? 'Posting...' : 'Post Questions to Comments'}
                </button>
              ) : (
                <span style={{ color: '#666', fontSize: '14px' }}>
                  Questions posted to comments
                </span>
              )}
            </div>
            {analysis.additionalQuestions?.length > 0 && (
              <ul style={styles.list}>
                {analysis.additionalQuestions?.map((question, index) => (
                  <li key={index} style={styles.listItem}>{question}</li>
                ))}
              </ul>
            )}
          </TabPanel>

          <TabPanel style={styles.tabContent}>
            <h2 style={styles.sectionTitle}>Analysis Feedback</h2>
            <p style={styles.feedbackText}>
              Your feedback directly trains CodeMerlin! Each rating and comment is fed back to the AI, 
              helping it learn what makes a good analysis and how to improve future responses. 
              Tell us what worked and what didn't - your input actively shapes the system.
            </p>
            <form onSubmit={handleFeedbackSubmit}>
              <div>
                {[1, 2, 3, 4, 5].map((star) => (
                  <span
                    key={star}
                    onClick={() => setRating(star)}
                    style={{
                      cursor: 'pointer',
                      color: star <= rating ? 'gold' : 'gray',
                      fontSize: '24px',
                      marginRight: '5px',
                    }}
                  >
                    ★
                  </span>
                ))}
              </div>
              <textarea
                style={{
                  width: '100%',
                  padding: '12px',
                  marginTop: '10px',
                  borderRadius: '3px',
                  border: '1px solid #ddd',
                  fontSize: '14px',
                  lineHeight: '1.5',
                  minHeight: '100px'
                }}
                placeholder="Leave a comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <button type="submit" style={styles.button}>Submit Feedback</button>
            </form>
            {renderFeedbackHistory()}
          </TabPanel>
        </Tabs>
      </>
    );
  };

  // Add a listener for Jira issue updates
  useEffect(() => {
    if (ap) {
      const handleIssueUpdate = () => {
        console.log('Issue updated event triggered');
        fetchIssueContext();
      };
      
      ap.events.on('issue.updated', handleIssueUpdate);
      return () => {
        ap.events.off('issue.updated', handleIssueUpdate);
      };
    }
  }, [ap, fetchIssueContext]);

  useEffect(() => {
    console.log('State Update:', {
      isLoading,
      error,
      analysisExists,
      isGeneratingAnalysis,
      selectedProject,
      issueId
    });
  }, [isLoading, error, analysisExists, isGeneratingAnalysis, selectedProject, issueId]);

  const checkForUpdates = useCallback(async () => {
    console.log('Checking for updates...', {
      hasAp: !!ap,
      hasContext: !!ap?.context,
      analysisDate,
      issueUpdateTime
    });

    if (!ap?.context) {
      console.log('Missing AP context for update check');
      return;
    }

    // Check if analysisDate is valid (not epoch/invalid date)
    if (!analysisDate || new Date(analysisDate).getTime() <= 0) {
      console.log('No valid analysis date, marking as outdated');
      setIsAnalysisOutdated(true);
      return;
    }

    try {
      // Get issue context
      const context = await ap.context.getContext();
      const issueKey = context.jira.issue.key;

      // Get fresh issue update time
      const issueResponse = await ap.request(`/rest/api/3/issue/${issueKey}`);
      const issueData = JSON.parse(issueResponse.body);
      const newUpdateTime = new Date(issueData.fields.updated);
      const existingAnalysisDate = new Date(analysisDate);
      
      console.log('Update check:', {
        analysisDate: existingAnalysisDate.toISOString(),
        issueUpdateTime: newUpdateTime.toISOString(),
        isOutdated: existingAnalysisDate < newUpdateTime,
        isValidDate: existingAnalysisDate.getTime() > 0
      });
      
      setIsAnalysisOutdated(existingAnalysisDate < newUpdateTime);
      setIssueUpdateTime(newUpdateTime);

    } catch (error) {
      console.error('Error checking for updates:', error);
    }
  }, [ap, analysisDate]);

  // Add an effect to check for updates when component mounts and when analysis date changes
  useEffect(() => {
    if (analysisDate) {
      console.log('Analysis date changed, checking for updates...');
      checkForUpdates();
    }
  }, [analysisDate, checkForUpdates]);

  const fetchAnalysis = async (projectId, issueKey) => {
    try {
      const token = await ap.context.getToken();
      const response = await fetch(`/get-analysis?projectId=${projectId}&issueId=${issueKey}`, {
        method: 'GET',
        headers: {
          'Authorization': `JWT ${token}`,
          'Accept': 'application/json'
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log('Raw Analysis Result:', result);
      
      // Only process the result if it has actual analysis content
      if (result && result.analysis && Object.keys(result.analysis).length > 0) {
        setError(null);
        setAnalysisExists(true);
        
        if (result.generatedAt) {
          setAnalysisDate(result.generatedAt);
          console.log('Setting analysis date:', result.generatedAt);
        }
        
        setAnalysis(result.analysis);
        console.log('Setting analysis log ID:', result.analysisLogId);
        setAnalysisLogId(result.analysisLogId);
        
        return result;
      } else {
        console.log('Received incomplete analysis result, waiting for full data');
        return null;
      }
    } catch (error) {
      console.error('Error fetching analysis:', error);
      setError('An unexpected error occurred');
      setIsGeneratingAnalysis(false);
      return null;
    }
  };

  const fetchFeedback = async () => {
    console.log('Fetching feedback for:', { projectId: selectedProject?.id, issueId });
    if (!selectedProject?.id || !issueId) {
      console.error('Missing project ID or issue ID for feedback fetch');
      return;
    }

    try {
      const token = await ap.context.getToken();
      const response = await fetch(`/get-feedback?projectId=${selectedProject.id}&issueId=${issueId}`, {
        headers: {
          'Authorization': `JWT ${token}`,
          'Accept': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch feedback');
      }

      const feedback = await response.json();
      console.log('Received feedback:', feedback);
      setFeedbackHistory(feedback);
    } catch (error) {
      console.error('Error fetching feedback:', error);
    }
  };

  const renderFeedbackHistory = () => (
    <div style={styles.feedbackHistory}>
      <h3 style={styles.subSectionTitle}>Previous Feedback</h3>
      {feedbackHistory.length === 0 ? (
        <p>No feedback submitted yet.</p>
      ) : (
        feedbackHistory.map((feedback, index) => (
          <div key={index} style={styles.feedbackEntry}>
            <div style={styles.feedbackHeader}>
              <div style={styles.stars}>
                {[...Array(5)].map((_, i) => (
                  <span key={i} style={{ color: i < feedback.rating ? 'gold' : 'gray' }}>★</span>
                ))}
              </div>
              <span style={styles.feedbackDate}>
                {(() => {
                  const date = new Date(feedback.createdAt);
                  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
                })()}
              </span>
            </div>
            {feedback.comment && (
              <div style={styles.feedbackComment}>
                <ReactMarkdown>{feedback.comment}</ReactMarkdown>
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );

  useEffect(() => {
    console.log('Analysis Log ID state changed:', analysisLogId);
  }, [analysisLogId]);

  // Add effect to fetch feedback when tab is active
  useEffect(() => {
    if (activeTab === 5 && selectedProject?.id && issueId) { // 5 is the Feedback tab index (6th tab)
      console.log('Feedback tab is active, fetching feedback...');
      fetchFeedback();
    }
  }, [activeTab, selectedProject?.id, issueId]);

  // Update the date display function to handle invalid dates
  const formatAnalysisDate = (date) => {
    if (!date || new Date(date).getTime() <= 0) {
      return 'Not yet generated';
    }
    const analysisDate = new Date(date);
    return `${analysisDate.getFullYear()}-${String(analysisDate.getMonth() + 1).padStart(2, '0')}-${String(analysisDate.getDate()).padStart(2, '0')} ${String(analysisDate.getHours()).padStart(2, '0')}:${String(analysisDate.getMinutes()).padStart(2, '0')}`;
  };

  // Add the progress tracking effect
  useEffect(() => {
    if (isGeneratingAnalysis) {
      const startTime = Date.now();
      const duration = 60000; // 60 seconds
      
      const progressInterval = setInterval(() => {
        const elapsed = Date.now() - startTime;
        const newProgress = Math.min((elapsed / duration) * 100, 100);
        setAnalysisProgress(prev => ({ ...prev, analysis: newProgress }));
      }, 1000);

      return () => {
        clearInterval(progressInterval);
        setAnalysisProgress(prev => {
          const next = { ...prev };
          delete next.analysis;
          return next;
        });
      };
    }
  }, [isGeneratingAnalysis]);

  return (
    <div id="merlin-analysis-container" style={styles.container}>
      {renderHeader()}
      {renderContent()}
      <div style={{
        fontSize: '12px',
        color: '#6B778C',
        padding: '4px 0',
        marginTop: '16px',
        textAlign: 'center'
      }}>
        CodeMerlin is AI-powered and can make mistakes. All information should be checked and used for guidance, only.
      </div>
    </div>
  );
};

export default MerlinAnalysis;
