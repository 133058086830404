import React, { useState, useEffect, useCallback } from 'react';
import Modal from '@atlaskit/modal-dialog';
import TextField from '@atlaskit/textfield';

const Config = () => {
  const [repositories, setRepositories] = useState([]);
  const [projects, setProjects] = useState([]);
  const [ap, setAp] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isGitHubAuthenticated, setIsGitHubAuthenticated] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [integrations, setIntegrations] = useState([]);
  const [updatingRepos, setUpdatingRepos] = useState(new Set());
  const [organizations, setOrganizations] = useState([]);
  const [showOrgSelector, setShowOrgSelector] = useState(false);
  const [showPurgeForm, setShowPurgeForm] = useState(false);
  const [purgeConfirmText, setPurgeConfirmText] = useState('');
  const [isPurging, setIsPurging] = useState(false);
  const [isGitLabAuthenticated, setIsGitLabAuthenticated] = useState(false);
  const [gitLabGroups, setGitLabGroups] = useState([]);
  const [showGroupSelector, setShowGroupSelector] = useState(false);
  const [isGeneratingAnalysis, setIsGeneratingAnalysis] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);

  useEffect(() => {
    const initializeAP = async () => {
      if (window.AP) {
        try {
          await new Promise((resolve) => window.AP.context.getContext(resolve));
          setAp(window.AP);
          setIsLoading(false);
        } catch (error) {
          console.error('Error initializing AP:', error);
          setError('Error initializing Atlassian Connect. Please refresh the page.');
        }
      } else {
        console.error('AP is not available');
        setError('Atlassian Connect is not available. Please check your installation.');
      }
    };

    initializeAP();
  }, []);

  useEffect(() => {
    if (ap) {
      fetchProjects();
      fetchRepositories();
      fetchRepositoryIntegrations();
      // Check URL parameters for org/group selector flags
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('github_auth_success') === 'true' && urlParams.get('show_org_selector') === 'true') {
        // Fetch and show organizations
        fetchOrganizations();
      }
      if (urlParams.get('gitlab_auth_success') === 'true' && urlParams.get('show_group_selector') === 'true') {
        fetchGitLabGroups();
      }
    }
  }, [ap]);

  const fetchProjects = async () => {
    try {
      const response = await ap.request('/rest/api/3/project');
      const projectsData = JSON.parse(response.body);
      setProjects(projectsData.map(project => ({
        id: project.id,
        name: `${project.name} (${project.key})`
      })));
    } catch (error) {
      console.error('Error fetching projects:', error);
      setError('Error loading projects');
    }
  };

  const getBaseUrl = (ap) => {
    try {
      // First try to get from ap.flagsUrl
      if (ap?.flagsUrl) {
        return new URL(ap.flagsUrl).origin;
      }
      
      // Fallback to getting from window location
      if (window?.location?.href) {
        const currentUrl = new URL(window.location.href);
        // If we're on Atlassian domain, use the iframeUrl
        if (currentUrl.hostname.includes('atlassian.net')) {
          return ap?.iframeUrl ? new URL(ap.iframeUrl).origin : process.env.BASE_URL;
        }
        return currentUrl.origin;
      }
      
      // Final fallback to environment variable
      return process.env.BASE_URL;
    } catch (error) {
      console.error('Error getting base URL:', error);
      // Fallback to environment variable 
      return process.env.BASE_URL;
    }
  };

  const fetchRepositories = async () => {
    try {
      const token = await ap.context.getToken();
      const baseUrl = getBaseUrl(ap);
      
      if (!baseUrl) {
        throw new Error('Could not determine base URL');
      }

      const response = await fetch(`${baseUrl}/get-github-repos`, {
        method: 'GET',
        headers: {
          'Authorization': `JWT ${token}`,
          'Accept': 'application/json'
        },
        credentials: 'include'
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        if (response.status === 403 && errorData.error === 'LICENSE_INACTIVE') {
          setError(errorData.message);
          ap.flag.create({
            type: 'error',
            title: errorData.title,
            body: `${errorData.message} ${errorData.details}`,
            actions: errorData.actions?.map(action => ({
              text: action.text,
              callback: () => {
                window.open(action.url, '_blank');
              }
            }))
          });
        } else {
          throw new Error(errorData.message || `HTTP error - status: ${response.status}`);
        }
        return;
      }
      
      console.log('response', JSON.stringify(response));
      const result = await response.json();
      if (result && Array.isArray(result.repositories)) {
        setRepositories(result.repositories.map(repo => ({
          ...repo,
          isEditing: false,
          selectedProject: repo.projectId
        })));
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error fetching repositories:', error);
      setError(error.message || 'Error loading repositories');
      ap.flag.create({
        type: 'error',
        title: 'Error',
        body: error.message || 'Failed to load repositories. Please try again later.'
      });
      setIsGitHubAuthenticated(false);
    }
  };

  const fetchRepositoryIntegrations = async () => {
    try {
      const token = await ap.context.getToken();
      const baseUrl = getBaseUrl(ap);
      
      if (!baseUrl) {
        throw new Error('Could not determine base URL');
      }

      const response = await fetch(`${baseUrl}/get-repository-integrations`, {
        method: 'GET',
        headers: {
          'Authorization': `JWT ${token}`,
          'Accept': 'application/json'
        },
        credentials: 'include'
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const result = await response.json();
      if (result && Array.isArray(result.integrations)) {
        setIntegrations(result.integrations);
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error fetching repository integrations:', error);
      setError('Error loading repository integrations');
    }
  };

  const handleProjectChange = (repoId, projectId) => {
    setRepositories(repositories.map(repo =>
      repo.id === repoId ? { ...repo, selectedProject: projectId } : repo
    ));
  };

  const handleSave = async (repo) => {
    try {      
        console.log('Saving repository-project mapping for repo:', repo);
        
        const token = await ap.context.getToken();
        const baseUrl = getBaseUrl(ap);

        if (!baseUrl) {
          throw new Error('Could not determine base URL');
        }

        const response = await fetch(`${baseUrl}/save-repo-project-mapping`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${token}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                selectedProject: repo.selectedProject, 
                createdAt: repo.createdAt, 
                id: repo.id, 
                integrationId: repo.integrationId, 
                isEditing: repo.isEditing, 
                isPrivate: repo.isPrivate, 
                name: repo.name, 
                owner: repo.owner, 
                type: repo.type
            })
        });

        if (response.status !== 200) {
            throw new Error('Failed to save repository-project mapping');
        }

        console.log('Repository-project mapping saved successfully');
        setRepositories(repositories.map(r =>
            r.id === repo.id ? { ...r, isEditing: false } : r
        ));
    } catch (error) {
      console.error('Error saving repository-project mapping:', error);
      setError('Error saving repository-project mapping');
    }
  };

  const handleEdit = (repoId) => {
    setRepositories(repositories.map(repo =>
      repo.id === repoId ? { ...repo, isEditing: true } : repo
    ));
  };

  const handleGitHubAuth = async () => {
    try {
      console.log('Starting GitHub authentication');
      
      const token = await ap.context.getToken();
      const baseUrl = getBaseUrl(ap);
      
      if (!baseUrl) {
        throw new Error('Could not determine base URL');
      }

      const response = await fetch(`${baseUrl}/start-github-auth`, {
        method: 'GET',
        headers: {
          'Authorization': `JWT ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });

      if (response.status !== 200) {
        throw new Error('Failed to start GitHub authentication');
      }

      const data = await response.json();

      console.log('GitHub authentication data:', data);

      if (data.authUrl) {
        window.open(data.authUrl, '_parent');
      } else {
        throw new Error('No auth URL received');
      }

      console.log('GitHub authentication started successfully');
    } catch (error) {
      console.error('Error starting GitHub auth:', error);
      setError('Failed to start GitHub authentication');
    }
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedRepositories = React.useMemo(() => {
    let sortableItems = [...repositories];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [repositories, sortConfig]);

  const handleUpdateIndex = async (repoId) => {
    // Add validation and logging
    if (!repoId) {
      console.error('Attempted to update repository with undefined ID');
      return;
    }

    // Log the repository we're trying to update
    const repo = repositories.find(r => r.id === repoId);
    console.log('Updating repository:', { repoId, repo });

    if (updatingRepos.has(repoId)) {
      console.log('Repository update already in progress:', repoId);
      return;
    }

    try {
      setRepositories(prev => prev.map(r => 
        r.id === repoId 
          ? { 
              ...r,  // Preserve all existing properties
              manifestGenerationStatus: 'in_progress',
              manifestGenerationMessage: 'Starting manifest generation...' 
            } 
          : r
      ));
      
      setUpdatingRepos(prev => new Set([...prev, repoId]));
      
      const token = await ap.context.getToken();
      const baseUrl = getBaseUrl(ap);
      
      const response = await fetch(`${baseUrl}/update-repo-index/${repoId}`, {
        method: 'POST',
        headers: {
          'Authorization': `JWT ${token}`,
          'Accept': 'application/json'
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error(`Failed to update repository: ${response.statusText}`);
      }

      let pollCount = 0;
      const maxPolls = 300; // Maximum number of polling  attempts (60 seconds) 
      
      const pollStatus = async () => {
        if (pollCount >= maxPolls) {
          setUpdatingRepos(prev => {
            const next = new Set(prev);
            next.delete(repoId);
            return next;
          });
          setRepositories(prev => prev.map(repo => 
            repo.id === repoId 
              ? { 
                  ...repo, 
                  manifestGenerationStatus: 'error',
                  manifestGenerationMessage: 'Timeout waiting for update' 
                } 
              : repo
          ));
          return;
        }

        try {
          const token = await ap.context.getToken();
          const baseUrl = getBaseUrl(ap);
          
          const statusResponse = await fetch(`${baseUrl}/get-repository/${repoId}`, {
            headers: {
              'Authorization': `JWT ${token}`,
              'Accept': 'application/json'
            },
            credentials: 'include'
          });

          if (!statusResponse.ok) throw new Error('Failed to fetch status');
          
          const repoData = await statusResponse.json();
          
          if (!repoData) {
            throw new Error('No repository data received');
          }

          setRepositories(prev => prev.map(repo => 
            repo.id === repoId 
              ? {
                  ...repo,  // Keep existing  repo data
                  manifestGenerationStatus: repoData.manifestGenerationStatus,
                  manifestGenerationMessage: repoData.manifestGenerationMessage
                }
              : repo
          ));

          if (repoData.manifestGenerationStatus === 'in_progress') {
            pollCount++;
            setTimeout(pollStatus, 30000);
          } else {
            setUpdatingRepos(prev => {
              const next = new Set(prev);
              next.delete(repoId);
              return next;
            });
          }
        } catch (error) {
          console.error('Error polling status:', error);
          setRepositories(prev => prev.map(repo => 
            repo.id === repoId 
              ? { 
                  ...repo, 
                  manifestGenerationStatus: 'error',
                  manifestGenerationMessage: error.message || 'Failed to fetch repository status' 
                } 
              : repo
          ));
          setUpdatingRepos(prev => {
            const next = new Set(prev);
            next.delete(repoId);
            return next;
          });
        }
      };

      pollStatus();

    } catch (error) {
      console.error('Error updating repository:', { repoId, error });
      
      setRepositories(prev => prev.map(r => 
        r.id === repoId 
          ? { 
              ...r,
              manifestGenerationStatus: 'error',
              manifestGenerationMessage: error.message || 'Failed to start index update' 
            } 
          : r
      ));

      setUpdatingRepos(prev => {
        const next = new Set(prev);
        next.delete(repoId);
        return next;
      });
    }
  };

  const getStatusBadgeStyle = (status) => {
    const baseStyle = {
      padding: '4px 8px',
      borderRadius: '12px',
      fontSize: '12px',
      fontWeight: 'bold',
    };

    switch (status) {
      case 'completed':
        return { ...baseStyle, backgroundColor: '#00875A', color: 'white' };
      case 'error':
        return { ...baseStyle, backgroundColor: '#DE350B', color: 'white' };
      case 'in_progress':
        return { ...baseStyle, backgroundColor: '#0052CC', color: 'white' };
      case 'pending':
        return { ...baseStyle, backgroundColor: '#0052CC', color: 'white' };
      default:
        return { ...baseStyle, backgroundColor: '#6B778C', color: 'white' };
    }
  };

  const StatusIndicator = ({ isUpdating, repoId }) => {
    if (!isUpdating) {
      return (
        <div 
          onClick={() => handleUpdateIndex(repoId)}
          style={{ 
            padding: '4px',
            borderRadius: '50%',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
            <path d="M17.65 6.35A7.958 7.958 0 0012 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08A5.99 5.99 0 0112 18c-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"/>
          </svg>
        </div>
      );
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: '8px', fontSize: '12px', color: '#6B778C' }}>
          Analyzing
        </span>
        <svg 
          width="16" 
          height="16" 
          viewBox="0 0 24 24" 
          fill="currentColor"
          style={{
            animation: 'spin 2s linear infinite'
          }}
        >
          <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm8 12c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zm-19 0c0-6.065 4.935-11 11-11v2c-4.962 0-9 4.038-9 9 0 2.481 1.009 4.731 2.639 6.361l-1.414 1.414c-2.036-2.036-3.225-4.686-3.225-7.775z"/>
        </svg>
      </div>
    );
  };

  const renderRepositoriesTable = () => (
    <table style={styles.table}>
      <thead>
        <tr>
          <th style={styles.th} onClick={() => handleSort('owner')}>Owner</th>
          <th style={styles.th} onClick={() => handleSort('name')}>Name</th>
          <th style={styles.th} onClick={() => handleSort('url')}>URL</th>
          <th style={styles.th} onClick={() => handleSort('selectedProject')}>Project</th>
          <th style={styles.th}>Actions</th>
          <th style={styles.th} onClick={() => handleSort('manifestGenerationStatus')}>Indexing Status</th>
        </tr>
      </thead>
      <tbody>
        {sortedRepositories.map(repo => (
          <tr key={repo.id}>
            <td style={styles.td}>{repo.owner}</td>
            <td style={styles.td}>{repo.name}</td>
            <td style={styles.td}>{repo.url}</td>
            <td style={styles.td}>
              {repo.isEditing ? (
                <select
                  value={repo.selectedProject || ''}
                  onChange={(e) => handleProjectChange(repo.id, e.target.value)}
                  style={styles.select}
                >
                  <option value="">Select Project</option>
                  {projects.map(project => (
                    <option key={project.id} value={project.id}>{project.name}</option>
                  ))}
                </select>
              ) : (
                projects.find(p => p.id === repo.selectedProject)?.name || 
                <span style={styles.notSet}>Not set</span>
              )}
            </td>
            <td style={styles.td}>
              {repo.isEditing ? (
                <button onClick={() => handleSave(repo)} style={styles.button}>Save</button>
              ) : (
                <button onClick={() => handleEdit(repo.id)} style={styles.button}>Edit</button>
              )}
            </td>
            <td style={styles.td}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <div style={getStatusBadgeStyle(repo.manifestGenerationStatus)}>
                  {repo.manifestGenerationStatus || 'unknown'}
                </div>
                {repo.selectedProject && (
                  <div 
                    style={{ 
                      color: updatingRepos.has(repo.id) ? '#0065FF' : '#0052CC',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    title="Update Index"
                  >
                    <StatusIndicator 
                      isUpdating={updatingRepos.has(repo.id)}
                      repoId={repo.id}
                    />
                  </div>
                )}
              </div>
              {repo.manifestGenerationMessage && (
                <div style={{ fontSize: '12px', color: '#6B778C', marginTop: '4px' }}>
                  {repo.manifestGenerationMessage}
                </div>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const handleSyncRepositories = async (integrationId) => {
    try {
      setIsSyncing(true);
      const token = await ap.context.getToken();
      const baseUrl = getBaseUrl(ap);
      
      if (!baseUrl) {
        throw new Error('Could not determine base URL');
      }

      const response = await fetch(`${baseUrl}/sync-repositories/${integrationId}`, {
        method: 'POST',
        headers: {
          'Authorization': `JWT ${token}`,
          'Accept': 'application/json'
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log('Repository sync result:', result);
      
      // Refresh the repositories list after syncing
      await fetchRepositories();

      window.AP.flag.create({
        type: 'success',
        title: 'Repositories Synchronized',
        body: `Successfully synced ${result.repositories?.length || 0} repositories.`
      });
    } catch (error) {
      console.error('Error syncing repositories:', error);
      window.AP.flag.create({
        type: 'error',
        title: 'Sync Failed',
        body: 'Failed to synchronize repositories. Please try again.'
      });
    } finally {
      setIsSyncing(false);
    }
  };

  const renderIntegrationsTable = () => (
    <table style={styles.table}>
      <thead>
        <tr>
          <th style={styles.th}>System</th>
          <th style={styles.th}>Authorizing User</th>
          <th style={styles.th}>Organizations Approved</th>
          <th style={styles.th}>Actions</th>
        </tr>
      </thead>
      <tbody>
        {integrations.map(integration => (
          <tr key={integration.id}>
            <td style={styles.td}>{integration.system}</td>
            <td style={styles.td}>{integration.userData?.name || integration.userData?.username || 'N/A'}</td>
            <td style={styles.td}>
              {integration.organizations?.map(org => org.fullPath).join(', ') || 'None'}
            </td>
            <td style={styles.td}>
              <button 
                onClick={() => handleSyncRepositories(integration.id)} 
                style={styles.button}
                disabled={isSyncing}
              >
                {isSyncing ? 'Syncing...' : 'Sync Repositories'}
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const handleOrgSelection = async (orgId) => {
    try {
      const token = await ap.context.getToken();
      const baseUrl = getBaseUrl(ap);
      const response = await fetch(`${baseUrl}/select-github-org`, {
        method: 'POST',
        headers: {
          'Authorization': `JWT ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orgId })
      });

      if (!response.ok) {
        throw new Error('Failed to select organization');
      }

      // Refresh the integrations list
      await fetchRepositoryIntegrations();
      setShowOrgSelector(false);
    } catch (error) {
      console.error('Error selecting organization:', error);
      setError('Failed to select organization');
    }
  };

  const renderOrgSelector = () => (
    showOrgSelector && (
      <div style={styles.orgSelector}>
        <h3>Select GitHub Organization</h3>
        <div style={styles.orgList}>
          {organizations.map(org => (
            <button
              key={org.id}
              onClick={() => handleOrgSelection(org.id)}
              style={styles.orgButton}
            >
              {org.login}
            </button>
          ))}
        </div>
      </div>
    )
  );

  const fetchOrganizations = async () => {
    try {
      const token = await ap.context.getToken();
      const baseUrl = getBaseUrl(ap);
      const response = await fetch(`${baseUrl}/get-github-orgs`, {
        method: 'GET',
        headers: {
          'Authorization': `JWT ${token}`,
          'Accept': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch organizations');
      }

      const data = await response.json();
      setOrganizations(data.organizations);
      setShowOrgSelector(true);
    } catch (error) {
      console.error('Error fetching organizations:', error);
      setError('Failed to fetch organizations');
    }
  };

  const handlePurgeData = async () => {
    if (purgeConfirmText !== 'DELETE') {
      ap.flag.error({ title: 'Error', body: 'Please type DELETE to confirm' });
      return;
    }

    try {
      setIsPurging(true);
      const token = await ap.context.getToken();
      const baseUrl = getBaseUrl(ap);
      
      const response = await fetch(`${baseUrl}/purge-account-data`, {
        method: 'DELETE',
        headers: {
          'Authorization': `JWT ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ confirmText: purgeConfirmText })
      });

      if (!response.ok) {
        throw new Error('Failed to purge data');
      }

      window.AP.flag.create({
        type: 'success',
        title: 'Success',
        body: 'All data has been purged from CodeMerlin'
      });
      
      setShowPurgeForm(false);
      setPurgeConfirmText('');
      
      setTimeout(() => {
        console.log('Reloading page...');
        window.scrollTo(0, 0);  // Scroll to top first
        setTimeout(() => {      // Small delay to ensure scroll completes
          window.location.href = window.location.href;
        }, 100);
      }, 1500);
    } catch (error) {
      console.error('Error purging data:', error);
      ap.flag.error({ title: 'Error', body: 'Failed to purge data. Please try again.' });
    } finally {
      setIsPurging(false);
    }
  };

  const handleGitLabAuth = async () => {
    try {
      console.log('Starting GitLab authentication');
      
      const token = await ap.context.getToken();
      const baseUrl = getBaseUrl(ap);
      
      if (!baseUrl) {
        throw new Error('Could not determine base URL');
      }

      const response = await fetch(`${baseUrl}/start-gitlab-auth`, {
        method: 'GET',
        headers: {
          'Authorization': `JWT ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });

      if (response.status !== 200) {
        throw new Error('Failed to start GitLab authentication');
      }

      const data = await response.json();
      console.log('GitLab authentication data:', data);

      if (data.authUrl) {
        window.open(data.authUrl, '_parent');
      } else {
        throw new Error('No auth URL received');
      }

      console.log('GitLab authentication started successfully');
    } catch (error) {
      console.error('Error starting GitLab auth:', error);
      setError('Failed to start GitLab authentication');
    }
  };

  const fetchGitLabGroups = async () => {
    try {
      const token = await ap.context.getToken();
      const baseUrl = getBaseUrl(ap);
      const response = await fetch(`${baseUrl}/get-gitlab-groups`, {
        method: 'GET',
        headers: {
          'Authorization': `JWT ${token}`,
          'Accept': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch groups');
      }

      const data = await response.json();
      setGitLabGroups(data.groups);
      setShowGroupSelector(true);
    } catch (error) {
      console.error('Error fetching GitLab groups:', error);
      setError('Failed to fetch GitLab groups');
    }
  };

  const handleGroupSelection = async (groupId) => {
    try {
      const token = await ap.context.getToken();
      const baseUrl = getBaseUrl(ap);
      const response = await fetch(`${baseUrl}/select-gitlab-group`, {
        method: 'POST',
        headers: {
          'Authorization': `JWT ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ groupId })
      });

      if (!response.ok) {
        throw new Error('Failed to select group');
      }

      // Refresh the integrations list
      await fetchRepositoryIntegrations();
      setShowGroupSelector(false);
    } catch (error) {
      console.error('Error selecting GitLab group:', error);
      setError('Failed to select group');
    }
  };

  const renderGroupSelector = () => (
    showGroupSelector && (
      <div style={styles.orgSelector}>
        <h3>Select GitLab Group</h3>
        <div style={styles.orgList}>
          {gitLabGroups.map(group => (
            <button
              key={group.id}
              onClick={() => handleGroupSelection(group.id)}
              style={styles.orgButton}
            >
              {group.name}
            </button>
          ))}
        </div>
      </div>
    )
  );

  if (isLoading) return <div>Loading...</div>;
  if (error) return (
    <div style={styles.errorContainer}>
      <div style={styles.errorCard}>
        <div style={styles.errorIcon}>
          <svg width="32" height="32" viewBox="0 0 24 24" fill="#DE350B">
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"/>
          </svg>
        </div>
        <h2 style={styles.errorTitle}>License Not Active</h2>
        <p style={styles.errorMessage}>{error}</p>
        <button 
          onClick={() => window.open('https://marketplace.atlassian.com/apps/1227073/codemerlin', '_blank')}
          style={styles.marketplaceButton}
        >
          Visit Marketplace
        </button>
      </div>
    </div>
  );

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Amulent CodeMerlin Configuration</h1>
      <div style={styles.authSection}>
        <div style={styles.githubAuth}>
          {!isGitHubAuthenticated ? (
            <button onClick={handleGitHubAuth} style={styles.button}>
              Authenticate with GitHub
            </button>
          ) : (
            <p>GitHub authenticated</p>
          )}
        </div>
        <div style={styles.gitlabAuth}>
          {!isGitLabAuthenticated ? (
            <button onClick={handleGitLabAuth} style={styles.button}>
              Authenticate with GitLab
            </button>
          ) : (
            <p>GitLab authenticated</p>
          )}
        </div>
      </div>
      <h2 style={styles.subheading}>Repository Integrations</h2>
      {renderIntegrationsTable()}
      <h2 style={styles.subheading}>Code Repositories</h2>
      {renderRepositoriesTable()}
      {renderOrgSelector()}
      {renderGroupSelector()}
      <div style={styles.dangerZone}>
        <h2 style={styles.dangerTitle}>Danger Zone</h2>
        <button 
          onClick={() => setShowPurgeForm(!showPurgeForm)} 
          style={styles.dangerButton}
        >
          Delete All Data In CodeMerlin
        </button>
        {showPurgeForm && (
          <div style={styles.purgeForm}>
            <p style={styles.warningText}>
              This action will permanently delete all Customer Data from CodeMerlin, including:
            </p>
            <ul style={styles.warningList}>
              <li>All code repository indexes</li>
              <li>All integrations</li>
              <li>All issue data</li>
              <li>All analysis history</li>
            </ul>
            <p style={styles.confirmText}>
              This action cannot be undone. Please type DELETE to confirm:
            </p>
            <TextField
              value={purgeConfirmText}
              onChange={(e) => setPurgeConfirmText(e.target.value)}
              placeholder="Type DELETE to confirm"
              isRequired
            />
            <button 
              onClick={handlePurgeData}
              style={styles.dangerButton}
              disabled={purgeConfirmText !== 'DELETE' || isPurging}
            >
              {isPurging ? 'Purging...' : 'Delete All Data'}
            </button>
          </div>
        )}
      </div>
      
      <div style={styles.footer}>
        <h3 style={styles.footerTitle}>Important Links</h3>
        <div style={styles.footerLinks}>
          <a href="https://www.amulent.com/data-processing-addendum" target="_blank" rel="noopener noreferrer" style={styles.footerLink}>
            Data Processing Addendum
          </a>
          <a href="https://www.amulent.com/privacy" target="_blank" rel="noopener noreferrer" style={styles.footerLink}>
            Privacy Policy
          </a>
          <a href="https://www.amulent.com/security" target="_blank" rel="noopener noreferrer" style={styles.footerLink}>
            Security Statement
          </a>
          <a href="https://www.amulent.com/subprocessors" target="_blank" rel="noopener noreferrer" style={styles.footerLink}>
            Subprocessors
          </a>
          <a href="https://www.amulent.com/terms-of-service" target="_blank" rel="noopener noreferrer" style={styles.footerLink}>
            Terms of Service
          </a>
          <a href="https://amulent.atlassian.net" target="_blank" rel="noopener noreferrer" style={styles.footerLink}>
            Support
          </a>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '30px',
    backgroundColor: 'white',
    borderRadius: '0',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  heading: {
    color: '#0052CC',
    fontSize: '24px',
    marginBottom: '20px',
  },
  subheading: {
    marginTop: '30px',
    fontSize: '20px',
    color: '#172B4D',
  },
  authSection: {
    display: 'flex',
    gap: '20px',
    marginBottom: '20px',
  },
  githubAuth: {
    marginTop: '20px',
    marginBottom: '30px',
  },
  gitlabAuth: {
    marginTop: '20px',
    marginBottom: '30px',
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0',
    marginTop: '20px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  },
  th: {
    textAlign: 'left',
    padding: '16px',
    borderBottom: '2px solid #DFE1E6',
    backgroundColor: '#F4F5F7',
    color: '#172B4D',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  td: {
    padding: '16px',
    borderBottom: '1px solid #DFE1E6',
  },
  select: {
    width: '100%',
    padding: '8px',
    border: '1px solid #DFE1E6',
    borderRadius: '3px',
  },
  button: {
    padding: '8px 16px',
    backgroundColor: '#0052CC',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
    fontSize: '14px',
    marginRight: '10px',
    transition: 'background-color 0.3s ease',
  },
  keyframes: `
    @keyframes spin {
      from { transform: rotate(0deg); }
      to { transform: rotate(360deg); }
    }
  `,
  orgSelector: {
    padding: '20px',
    backgroundColor: '#F4F5F7',
    borderRadius: '4px',
    marginTop: '20px',
  },
  orgList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  orgButton: {
    padding: '8px 16px',
    backgroundColor: 'white',
    border: '1px solid #DFE1E6',
    borderRadius: '3px',
    cursor: 'pointer',
    textAlign: 'left',
  },
  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '80vh',
    padding: '20px',
    backgroundColor: '#F4F5F7',
  },
  errorCard: {
    backgroundColor: 'white',
    borderRadius: '3px',
    padding: '40px',
    textAlign: 'center',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    maxWidth: '500px',
    width: '100%',
  },
  errorIcon: {
    marginBottom: '16px',
  },
  errorTitle: {
    color: '#172B4D',
    fontSize: '24px',
    marginBottom: '16px',
    fontWeight: '500',
  },
  errorMessage: {
    color: '#42526E',
    fontSize: '16px',
    lineHeight: '1.5',
    marginBottom: '24px',
  },
  marketplaceButton: {
    backgroundColor: '#0052CC',
    color: 'white',
    padding: '8px 24px',
    borderRadius: '3px',
    border: 'none',
    fontSize: '14px',
    fontWeight: '500',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    ':hover': {
      backgroundColor: '#0065FF',
    },
  },
  dangerZone: {
    marginTop: '40px',
    padding: '24px',
    border: '1px solid #BF2600',
    borderRadius: '3px'
  },
  dangerTitle: {
    color: '#BF2600',
    fontSize: '16px',
    marginBottom: '16px'
  },
  dangerButton: {
    backgroundColor: '#BF2600',
    color: 'white',
    padding: '8px 16px',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
    fontSize: '14px',
    transition: 'background-color 0.2s ease',
    '&:hover': {
      backgroundColor: '#DE350B'
    }
  },
  modalContent: {
    padding: '24px 0',
    fontSize: '14px',
    lineHeight: '1.5',
  },
  warningText: {
    marginBottom: '16px',
    color: '#172B4D',
    fontWeight: 500
  },
  warningList: {
    marginBottom: '24px',
    paddingLeft: '24px',
    color: '#172B4D'
  },
  confirmText: {
    marginBottom: '16px',
    color: '#172B4D'
  },
  purgeForm: {
    marginTop: '20px',
    padding: '20px',
    backgroundColor: '#F4F5F7',
    borderRadius: '4px',
  },
  footer: {
    marginTop: '40px',
    paddingTop: '24px',
    borderTop: '1px solid #DFE1E6',
  },
  footerTitle: {
    fontSize: '16px',
    color: '#172B4D',
    marginBottom: '16px',
  },
  footerLinks: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
  },
  footerLink: {
    color: '#0052CC',
    textDecoration: 'none',
    fontSize: '14px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  notSet: {
    backgroundColor: '#FFEBE6', // Light red background
    color: '#DE350B',          // Red text
    padding: '2px 8px',
    borderRadius: '3px',
    fontWeight: 'bold',
    fontSize: '12px'
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' }
  }
};

export default Config;
